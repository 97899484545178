/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

import { generateCode } from '@/utils/generateCode';
import {
  AppGraph,
  Component,
  LinkType,
  NodeType,
  Provider,
  SubDataArrayType,
} from 'types/api-data';
import { OptionsType } from 'types/multi-select';
import { IPrompt } from 'types/prompt';
import { ProvidersJSON, SupportedFeature } from 'types/providers';

type IconMapType = { [key: string]: string };

export const iconMap: IconMapType = {
  'workflow.langchain': '/logos/provider/langchain.svg',
  'workflow.llamaindex': '/logos/provider/LlamaSquareBlack.svg',
  'workflow.haystack': '/logos/provider/haystack.svg',
  'model.llm': '/logos/provider/model-llm.svg',
  'model.embedding': '/logos/provider/embedding_model.svg',
  'model.generic': '/logos/provider/model-generic.svg',
  'app_hosting.aws_lambda': '/logos/provider/lambda.svg',
  'app_hosting.github_codespace': '/logos/provider/github-codespace.svg',
  'app_hosting.azure_func': '/logos/service/azure-function.svg',
  'inference.triton': '/logos/provider/nvidia.svg',
  'infra.kubernetes': '/logos/service/kubernetes.svg',
  'inference.azure_openai': '/logos/service/azure-openai.svg',
  'inference.openai': '/logos/provider/openai.svg',
  'inference.openai.model': '/logos/provider/openai.svg',
  'inference.aws_bedrock': '/logos/service/bedrock.svg',
  'inference.aws_sagemaker': '/logos/service/sagemaker.svg',
  'app_hosting.azure_mlw': '/logos/service/azure-ml.svg',
  'vectorstore.chroma': '/logos/service/chroma.svg',
  'vectorstore.opensearchvectorsearch': '/logos/service/aws-opensearch.svg',
  azure: '/logos/provider/microsoft-azure.svg',
  aws: '/logos/provider/aws.svg',
  github: '/logos/provider/github.svg',
  gcp: '/logos/provider/gcp.svg',
  openai: '/logos/provider/openai.svg',
  default: '/logos/provider/not-available.svg',
};

export const multiSelectDataTransform = (
  data: Component[] | Provider[] | undefined,
  model?: boolean
) => {
  const subData: OptionsType = [];
  let id = 0;
  let name = '';
  let label = '';

  data?.forEach((entity: any) => {
    if (model) {
      name = entity?.properties?.ml_model;
      label = entity?.properties?.ml_model;
    } else {
      name = entity?.component_name || entity?.provider_name;
      label = entity?.display_name;
    }
    subData.push({ id, value: name, label });
    id++;
  });

  id = 0;

  return subData;
};

export const removeFilterDuplicates = (arr: OptionsType) => {
  const seen = new Set();
  return arr.filter((item) => {
    const duplicate = seen.has(item.value);
    seen.add(item.value);
    return !duplicate;
  });
};

export const graphDataTransform = (
  data: AppGraph
): {
  node: NodeType[];
  link: LinkType[];
} => {
  const filterLogical = data.components.filter(
    (item) => item.domain === 'logical'
  );

  let key = 0;
  const subDataArray: SubDataArrayType[] = [];
  filterLogical.forEach(({ display_name, component_name, type, status }) => {
    subDataArray.push({ key, display_name, component_name, type, status });
    key++;
  });

  key = 0;

  const nodeDataArray: NodeType[] = [];
  const linkDataArray: LinkType[] = [];

  filterLogical.forEach(({ display_name, type }) => {
    nodeDataArray.push({
      key,
      name: display_name,
      source: iconMap[type] ?? iconMap['default'],
    });
    key++;
  });

  key = 0;

  const filterConsumes = data.dependencies.filter(
    (item) => item.dependency_type === 'consumes'
  );

  const keyLookUp = (name: string) => {
    const obj = subDataArray?.filter((item) => item.component_name === name);

    return obj[0]?.key;
  };

  filterConsumes.forEach((dependency) => {
    linkDataArray.push({
      key: key++,
      from: keyLookUp(dependency.source),
      to: keyLookUp(dependency.target),
    });
  });

  return { node: nodeDataArray, link: linkDataArray };
};

export const formatDuration = (durationMs: number, short?: boolean) => {
  const milliseconds = durationMs % 1000;
  const seconds = Math.floor((durationMs / 1000) % 60);
  const minutes = Math.floor((durationMs / (1000 * 60)) % 60);
  const hours = Math.floor((durationMs / (1000 * 60 * 60)) % 24);
  const days = Math.floor(durationMs / (1000 * 60 * 60 * 24));

  let result = '';

  if (short) {
    if (days > 0) {
      result += `${days}d `;
    }
    if (hours > 0) {
      result += `${hours}h `;
    }
    if (minutes > 0) {
      result += `${minutes}m `;
    }
    if (seconds > 0 || durationMs < 1000) {
      result += `${seconds}s `;
    }
    if (milliseconds > 0 && durationMs < 1000) {
      result += `${milliseconds}ms `;
    }
  } else {
    if (days > 0) {
      result += `${days} day${days > 1 ? 's' : ''} `;
    }
    if (hours > 0) {
      result += `${hours} hour${hours > 1 ? 's' : ''} `;
    }
    if (minutes > 0) {
      result += `${minutes} minute${minutes > 1 ? 's' : ''} `;
    }
    if (seconds > 0 || durationMs < 1000) {
      result += `${seconds} second${seconds > 1 ? 's' : ''} `;
    }
    if (milliseconds > 0 && durationMs < 1000) {
      result += `${milliseconds} millisecond${milliseconds > 1 ? 's' : ''} `;
    }
  }

  return result.trim();
};

export const formatSecondsAndMilliseconds = (durationMs: number) => {
  const milliseconds = durationMs % 1000;
  const seconds = Math.floor((durationMs / 1000) % 60);

  let result = '';

  if (seconds > 0) {
    result += `${seconds}s `;
  }

  if (milliseconds > 0 || seconds === 0) {
    result += `${milliseconds}ms `;
  }

  return result.trim();
};

export const removeDuplicates = (data: SupportedFeature[]) => {
  const uniqueItems: SupportedFeature[] = [];
  const seen = new Set();

  data.forEach((item) => {
    const identifier = `${item.type}-${item.display_name}`;
    if (!seen.has(identifier)) {
      seen.add(identifier);
      uniqueItems.push(item);
    }
  });

  return uniqueItems;
};

// Function to process providers and create an array of objects with supported features
export const processProviders = (data: ProvidersJSON) => {
  const result: SupportedFeature[] = [];

  // Loop through each provider
  data.providers.forEach((provider) => {
    const features = provider.supported_functions;

    // Add each feature to the result array with provider info
    features.forEach((feature) => {
      result.push(feature);
    });
  });

  return result;
};

// Function to create name with a random 6 char suffix
export const createNameWithSuffix = (name: string, charNo = 7) => {
  let baseName = name.trim().toLowerCase().replaceAll(' ', '_');

  const maxBaseNameLength = 64 - charNo;
  if (baseName.length > maxBaseNameLength) {
    baseName = baseName.slice(0, maxBaseNameLength);
  }
  const uniqueCode = generateCode();
  return `${baseName}_${uniqueCode}`;
};

export const formatPromptOutput = (promptOutput: IPrompt) => {
  if (Array.isArray(promptOutput?.output?.response)) {
    return promptOutput?.output?.response[0];
  }
  return promptOutput?.output?.response;
};

export const formatPromptInput = (promptInput: IPrompt) => {
  if (Array.isArray(promptInput?.input?.input)) {
    return promptInput?.input?.input[0];
  }
  return promptInput?.input?.question;
};
